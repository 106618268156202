import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/company.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/contact.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/index.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/detail.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/index.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/office.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/policy.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/recruit.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/workDetail.vue?macro=true";
import { default as C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta } from "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/works.vue?macro=true";
export default [
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta?.name ?? "company",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta?.path ?? "/company",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/company.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47company_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/company.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta?.name ?? "contact",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta?.path ?? "/contact",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/contact.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47contact_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta?.name ?? "index",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta?.path ?? "/",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/index.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta?.name ?? "notice-detail",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta?.path ?? "/notice/detail",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/detail.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47detail_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/detail.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta?.name ?? "notice",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta?.path ?? "/notice",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/index.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47notice_47index_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/notice/index.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta?.name ?? "office",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta?.path ?? "/office",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/office.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47office_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/office.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta?.name ?? "policy",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta?.path ?? "/policy",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/policy.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47policy_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta?.name ?? "recruit",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta?.path ?? "/recruit",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/recruit.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47recruit_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/recruit.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta?.name ?? "workDetail",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta?.path ?? "/workDetail",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/workDetail.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47workDetail_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/workDetail.vue").then(m => m.default || m)
  },
  {
    name: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta?.name ?? "works",
    path: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta?.path ?? "/works",
    file: "C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/works.vue",
    children: [],
    meta: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta,
    alias: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta?.alias || [],
    redirect: C_58_47Users_47JIN_PG004_47Documents_47JINStudio_47CorporateSite_47src_47pages_47works_46vueMeta?.redirect || undefined,
    component: () => import("C:/Users/JIN_PG004/Documents/JINStudio/CorporateSite/src/pages/works.vue").then(m => m.default || m)
  }
]