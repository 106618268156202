<template>
  <div class="l-wrapper">
    <Header />
    <Loading />
    <NuxtPage />
    <Footer />
  </div>
</template>
<script setup lang="ts">
import { onMounted } from 'vue'
onMounted(() => {
  document.documentElement.classList.add('loaded')

  const jsonLdScript = document.createElement('script')
  jsonLdScript.type = 'application/ld+json'
  jsonLdScript.innerHTML = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: '仁Studio株式会社',
    url: 'https://jinstudio.co.jp/',
    logo: 'https://jinstudio.co.jp/favicon.ico',
    sameAs: ['https://twitter.com/JINStudio_cojp'],
  })
  document.head.appendChild(jsonLdScript)
})
</script>
