<template>
  <section id="footer" class="footer">
    <footer>
      <LazyUiPartsCurveLineBox view-type="white" pos-type="bottom" />
      <div class="container">
        <div class="link-container">
          <ul class="nav">
            <li class="nav-item">
              <nuxt-link to="/recruit">
                <span class="color">RECRUIT</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link to="/contact">
                <span class="color">CONTACT</span>
              </nuxt-link>
            </li>
            <li class="nav-item">
              <nuxt-link to="/policy">
                <span class="color">PRIVACY POLICY</span>
              </nuxt-link>
            </li>
          </ul>
          <div class="sns">
            <span class="sns-item">
              <a :href="facebookUrl" target="_blank" rel="noopener">
                <AtomsImgEl :src="facebookIconPath" alt="facebook" />
              </a>
            </span>
            <span class="sns-item">
              <a :href="twitterUrl" target="_blank" rel="noopener">
                <AtomsImgEl :src="twitterIconPath" alt="x" />
              </a>
            </span>
            <span class="sns-item">
              <a :href="youtubeUrl" target="_blank" rel="noopener">
                <AtomsImgEl :src="youtubeIconPath" alt="youtube" />
              </a>
            </span>
          </div>
        </div>
        <div class="logo-container">
          <nuxt-link class="navbar-brand" to="/">
            <AtomsImgEl class="logo" :src="logoPath" alt="仁Studio株式会社" />
          </nuxt-link>
          <div class="text">&copy;&ensp;2024 JIN Studio Co., Ltd.</div>
        </div>
      </div>
    </footer>
  </section>
</template>
<script setup lang="ts">
const logoPath = '/img/footer_logo.webp?'
const twitterIconPath = '/img/sns/sns_x.svg?'
const twitterUrl = 'https://twitter.com/JINStudio_cojp'
const youtubeIconPath = '/img/sns/sns_youtube.svg?'
const youtubeUrl = 'https://www.youtube.com/channel/UCxGkLlvqCLm9_VMvwAiGUvg'
const discordIconPath = '/img/sns/sns_discord.svg?'
const discordUrl = ''
const facebookIconPath = '/img/sns/sns_facebook.svg?'
const facebookUrl = 'https://www.facebook.com/profile.php?id=100089504992002'
const instagramIconPath = '/img/sns/sns_instagram.svg?'
const instagramUrl = 'https://www.instagram.com/jinstudio_cojp'
const noteIconPath = '/img/sns/sns_note.svg?'
const noteUrl = 'https://note.com/jinstudio_cojp/'
</script>
<style lang="scss" scoped>
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/variables' as v;

#footer {
  color: #bebebe;
  background-color: #333;
  text-align: center;

  .logo {
    max-width: 278px;

    @include m.sp() {
      max-width: 222px;
    }
  }
}

.footer {
  z-index: v.$zindex-footer;
  position: sticky;
}

.container {
  display: flex;
  flex-flow: row-reverse;
  margin: -6px auto 0;
  max-width: 1000px;
  padding-bottom: 20px;
  padding-top: 54px;
  width: 96%;

  @include m.sp() {
    display: block;
    padding: 30px 0 18px;
    width: 100%;
  }
}

.link-container {
  width: 66%;

  @include m.sp() {
    width: 100%;
  }
}

.nav {
  list-style: none;
  margin: 0;
  padding-right: 36px;
  line-height: 1.3;

  @include m.sp() {
    padding-bottom: 16px;
  }
}

.nav-item {
  text-align: right;

  @include m.sp() {
    text-align: center;
  }
}

.nav-item .color {
  color: #bebebe;
}

.sns {
  border-top: 2px solid #bebebe;
  display: flex;
  justify-content: right;
  margin-top: 10px;
  padding-right: 24px;

  @include m.sp() {
    justify-content: center;
    padding-right: 0;
  }

  > .sns-item {
    margin-top: 16px;
    width: 48px;

    @include m.sp() {
      margin-top: 26px;
    }
  }

  > .sns-item-width {
    margin-top: 16px;
    width: 128px;

    @include m.sp() {
      margin-top: 26px;
    }
  }

  img {
    height: 20px;
  }
}

.logo-container {
  margin: 0 auto;
  padding-bottom: 60px;
  padding-top: 8px;
  width: 34%;
  min-width: 340px;

  @include m.sp() {
    padding-bottom: 20px;
    padding-top: 40px;
    width: 100%;
    min-width: auto;
  }

  > .text {
    font-size: 14px;
  }
}
</style>
